import React, { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";

import { useGetUserListQuery } from "../../../features/ac/acUserRoleApi";

import { useUserLogDataMutation } from "../../../features/ac/acUserLogApi";

import AcUserLog from "./AcUserLog";
import useTitle from "../../../hooks/useTitle";

function AcUserLogMain() {
  useTitle("Ac User Log");
  const theme = useContext(ThemeContext);

  const { data: userList } = useGetUserListQuery();

  const [userLogData, { isLoading }] = useUserLogDataMutation();

  const [tableAllData, setTableAllData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [dataView, setDataView] = useState(10);
  const [formData, setFormData] = useState({});

  const [tableLoading, setTableLoading] = useState(true);

  // const [date, setDate] = useState({
  //   startdate: null,
  //   enddate: null,
  // });

  let totalDataCount = 0;
  let tableData = [];
  let element = "";

  useEffect(() => {
    const initialObject = { dataview: dataView, page: activePage };
    setTableLoading(true);
    userLogData({ ...initialObject, ...formData })
      .unwrap()
      .then((payload) => setTableAllData(payload))
      .catch((error) => {
        console.log(error);
      });
  }, [activePage, dataView]);

  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    setActivePage(1);
    // console.log(formData);
    userLogData(formData)
      .unwrap()
      .then((payload) => setTableAllData(payload))
      .catch((error) => {
        console.log(error);
      });
    // if (date?.startdate && room_id && sensor_id) {
    //   dataLogFetch({
    //     dataview: dataView,
    //     page: 1,
    //     startdate: date?.startdate,
    //     enddate: date?.enddate,
    //     room_id: room_id,
    //     sensor_id: sensor_id,
    //   });
    // } else if (date?.startdate && room_id) {
    //   dataLogFetch({
    //     dataview: dataView,
    //     page: 1,
    //     startdate: date?.startdate,
    //     enddate: date?.enddate,
    //     room_id: room_id,
    //   });
    // } else if (date?.startdate && sensor_id) {
    //   dataLogFetch({
    //     dataview: dataView,
    //     page: 1,
    //     startdate: date?.startdate,
    //     enddate: date?.enddate,
    //     sensor_id: sensor_id,
    //   });
    // } else {
    //   dataLogFetch(date);
    // }

    // console.log(fetchedData);
  };

  if (!isLoading) {
    // console.log("Data Loaded");
    // console.log(tableAllData);
    const data = tableAllData.data || [];

    tableData = data.map((item) => {
      const data = new Date(item.created_at);
      const formattedDate = data.toLocaleString();

      return {
        ...item,
        created_at: formattedDate,
      };
    });

    totalDataCount = tableAllData?.total || 0;

    // if (tableData.length > 0) {
    //   element = (
    //     <AllNotification
    //       tableData={tableData}
    //       setActivePage={setActivePage}
    //       setDataView={setDataView}
    //       activePage={activePage}
    //       totalDataCount={totalDataCount}
    //     />
    //   );
    // } else {
    //   element = <div>No Data Found</div>;
    // }
  }

  const formChangeHandler = (e) => {
    if (e.target.value == "") {
      delete formData[e.target.name];
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  // const downloadCSVHandler = (e) => {
  //   e.preventDefault();
  //   const baseUrl = process.env.REACT_APP_API_URL + "/apiV2";
  //   const exportURL = `${baseUrl}/generator/data-export`;

  //   if (
  //     formData?.startdate &&
  //     formData?.combined_id &&
  //     formData?.generator_status
  //   ) {
  //     const exportUrlWithDate =
  //       exportURL +
  //       `?startdate=${formData?.startdate}&enddate=${formData?.enddate}&combined_id=${formData?.combined_id}&generator_status=${formData?.generator_status}`;
  //     window.location.replace(exportUrlWithDate);
  //   } else if (formData?.startdate && formData?.generator_status) {
  //     const exportUrlWithDate =
  //       exportURL +
  //       `?startdate=${formData?.startdate}&enddate=${formData?.enddate}&generator_status=${formData?.generator_status}`;
  //     window.location.replace(exportUrlWithDate);
  //   } else if (formData?.startdate && formData?.combined_id) {
  //     const exportUrlWithDate =
  //       exportURL +
  //       `?startdate=${formData?.startdate}&enddate=${formData?.enddate}&combined_id=${formData?.combined_id}`;
  //     window.location.replace(exportUrlWithDate);
  //   } else if (formData?.startdate) {
  //     const exportUrlWithDate =
  //       exportURL +
  //       `?startdate=${formData?.startdate}&enddate=${formData?.enddate}`;
  //     window.location.replace(exportUrlWithDate);
  //   } else {
  //     window.location.replace(exportURL);
  //   }
  // };

  return (
    <div className={`${theme}`}>
      <div className="row">
        <div
          className="theme-text"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "1rem",
          }}
        >
          User Log
        </div>
        <div className="col-md-10">
          <form
            onSubmit={(e) => searchSumbitHandler(e)}
            style={{ paddingBottom: "20px" }}
          >
            <div className="row">
              {/* <div className="form-group col-md-2">
                <label className="theme-text">Start Date </label>
                <input
                  type="date"
                  className="form-control"
                  name="startdate"
                  onChange={formChangeHandler}
                  required
                />
              </div>
              <div class="form-group col-md-2">
                <label className="theme-text">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="enddate"
                  onChange={formChangeHandler}
                  required
                />
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="status"
                  onChange={formChangeHandler}
                >
                  <option value="" selected>
                    Select Status
                  </option>
                  <option value="On">on</option>
                  <option value="Off">off</option>
                </select>
              </div> */}
              <div className="col-md-2 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="user_id"
                  onChange={formChangeHandler}
                >
                  <option value="" selected>
                    Select User
                  </option>
                  {userList?.data?.map((userLog) => {
                    return <option value={userLog?.id}>{userLog?.name}</option>;
                  })}
                </select>
              </div>

              <div className="col-md-2 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
              {/* <div className="col-md-2 date-search-submit-btn">
                <button
                  // type="submit"
                  class="btn btn-info col-md-12"
                  onClick={(e) => downloadCSVHandler(e)}
                >
                  {" "}
                  Download CSV
                </button>
              </div> */}
            </div>
          </form>
        </div>
      </div>
      {/* {element} */}
      <AcUserLog
        tableData={tableData}
        setActivePage={setActivePage}
        setDataView={setDataView}
        activePage={activePage}
        totalDataCount={totalDataCount}
        tableLoading={tableLoading}
      />
    </div>
  );
}

export default AcUserLogMain;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth")).accessToken
    : undefined,
  user: localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth")).user
    : undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      console.log("inside user Log in action");
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
    userLoggedOut: (state) => {
      console.log("Inside Log out");
      localStorage.clear();
      state.accessToken = undefined;
      state.user = undefined;
    },
  },
});

export const { userLoggedIn, userLoggedOut } = authSlice.actions;
export default authSlice.reducer;

import { apiSlice } from "../api/apiSlice";

export const acMaintenanceApi = apiSlice.injectEndpoints({
  tagTypes: ["maintenance"],
  endpoints: (builder) => ({
    // getAcMaintenanceList: builder.query({
    //   query: () => "/apiV2/ac/maintenance-list",
    //   providesTags: ["maintenance"],
    // }),
    getAcMaintenanceList: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/maintenance-list",
        method: "POST",
        body: data,
      }),
      providesTags: ["maintenance"],
    }),
    createMaintenance: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/maintenance",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["maintenance"],
    }),
    updateMaintenance: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/maintenance-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["maintenance"],
    }),
    deleteMaintenance: builder.mutation({
      query: ({ id }) => ({
        url: `/apiV2/ac/maintenance-delete?id=${id}`,
        method: "DELETE",
        // body: data,
      }),
      invalidatesTags: ["SensorMap"],
    }),

    getCompanyWiseBranchList: builder.query({
      query: (company_id) =>
        `/apiV2/ac/company-branch-list?company_id=${company_id}`,
      // providesTags: ["maintenance"],
    }),
    getCompanyAndBranchWiseAcList: builder.query({
      query: ({ company_id, branch_id }) =>
        `/apiV2/ac/branch-ac-list?company_id=${company_id}&branch_id=${branch_id}`,
      // providesTags: ["maintenance"],
    }),
  }),
});

export const {
  // useGetAcMaintenanceListQuery,
  useGetAcMaintenanceListMutation,
  useCreateMaintenanceMutation,
  useUpdateMaintenanceMutation,
  useDeleteMaintenanceMutation,

  useGetCompanyWiseBranchListQuery,
  useGetCompanyAndBranchWiseAcListQuery,
} = acMaintenanceApi;

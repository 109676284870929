import React, { useState } from "react";
import styles from "./acSchedule.module.css";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useDeleteScheduleSpecificTimeMutation } from "../../../features/ac/acSchedule";
import { useUpdateToggleIndividualScheduleMutation } from "../../../features/ac/acSchedule";
import Swal from "sweetalert2";

function AcTimeScheduleCard({ scheduleTimeData }) {
  const [deleteScheduleSpecificTime] = useDeleteScheduleSpecificTimeMutation();
  const [updateToggleIndividualSchedule] =
    useUpdateToggleIndividualScheduleMutation();
  // const handleDeleteSchedule = () => {
  //   deleteScheduleSpecificTime(scheduleTimeData?.schedule_id);
  // };
  const [isChecked, setIsChecked] = useState(
    scheduleTimeData?.status === "active"
  );
  const handleToggle = (event) => {
    // const newStatus = event.target.checked ? "active" : "inactive";
    setIsChecked(event.target.checked);
    // Swal.fire("Updated!", "Schedule status updated to inactive.", "success");
    updateToggleIndividualSchedule({
      scheduleId: scheduleTimeData?.schedule_id,
    })
      .unwrap()
      .then((payload) => {
        Swal.fire("Updated!", payload.message, "success");

        // console.log(payload?.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteSchedule = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteScheduleSpecificTime({
          scheduleId: scheduleTimeData?.schedule_id,
        })
          .unwrap()
          .then((payload) => {
            Swal.fire("Deleted!", "Your item has been deleted.", "success");
            console.log(payload?.message);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <div>
      <div className={`${styles.time_container}`}>
        <div className={`${styles.time}`}>
          {scheduleTimeData?.start_time} to {scheduleTimeData?.end_time}
        </div>
        <div>
          <div className={`${styles.right_portion}`}>
            <div className={`${styles.set_temp_container}`}>
              <div className={`${styles.temp_text}`}>Temp</div>
              <div className={`${styles.temp_value} ${styles.day_card}`}>
                {scheduleTimeData?.set_temp}°C
              </div>
            </div>

            <div className={`${styles.delete}`}>
              <div className={`${styles.icon_container}`}>
                <AiFillDelete color="#F5222D" />
              </div>
              <div
                className="text"
                style={{ color: "#F5222D", fontWeight: "bold" }}
                onClick={handleDeleteSchedule}
              >
                DELETE
              </div>
            </div>
            {/* <div className={`${styles.edit}`}>
            <div className={`${styles.icon_container}`}>
              <FaEdit />
            </div>
            <div className="text" style={{ fontWeight: "bold" }}>
              EDIT
            </div>
          </div> */}
            <div className={`${styles.toggle} ${styles.time_toggle}`}>
              <div>
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleToggle}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <div className={`${styles.set_temp_container}`}>
              <div className={`${styles.temp_text}`}>T.Min</div>
              <div className={`${styles.temp_value} ${styles.day_card}`}>
                {scheduleTimeData?.threshold_min}°C
              </div>
            </div>
            <div className={`${styles.set_temp_container}`}>
              <div className={`${styles.temp_text}`}>T.Max</div>
              <div className={`${styles.temp_value} ${styles.day_card}`}>
                {scheduleTimeData?.threshold_max}°C
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcTimeScheduleCard;

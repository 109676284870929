import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { AiFillDelete } from "react-icons/ai";
import { ThemeContext } from "../../../context/ThemeContext";

import { Table, Pagination, Input } from "rsuite";

import { SkipToken } from "@reduxjs/toolkit/dist/query";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//maintenance apis
import { useGetAcMaintenanceListMutation } from "../../../features/ac/acMaintenanceApi";
import { useCreateMaintenanceMutation } from "../../../features/ac/acMaintenanceApi";
import { useUpdateMaintenanceMutation } from "../../../features/ac/acMaintenanceApi";
import { useDeleteMaintenanceMutation } from "../../../features/ac/acMaintenanceApi";

import { useGetAcCompanyListQuery } from "../../../features/ac/acCompanyCreate";
import { useGetCompanyWiseBranchListQuery } from "../../../features/ac/acMaintenanceApi";
import { useGetCompanyAndBranchWiseAcListQuery } from "../../../features/ac/acMaintenanceApi";

import Swal from "sweetalert2";

// import "./style/sensorMap.css";
import useTitle from "../../../hooks/useTitle";

const animatedComponents = makeAnimated();

const { Column, HeaderCell, Cell } = Table;

function AcMaintenance() {
  useTitle("Ac Maintenance");
  const theme = useContext(ThemeContext);
  const [show, setShow] = useState(false);

  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [formData, setFormData] = useState({});
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const [searchFormData, setSearchFormData] = useState({});

  //maintanence  states
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [branchId, setBranchId] = useState("");

  //maintanence list states

  //maintenance apis

  const { data: companyList } = useGetAcCompanyListQuery();
  const { data: companyWiseBranchList, isLoading } =
    useGetCompanyWiseBranchListQuery(companyId || "");
  const { data: companyAndBranchWiseAcList } =
    useGetCompanyAndBranchWiseAcListQuery({
      company_id: companyId,
      branch_id: branchId,
    });

  const [getAcMaintenanceList] = useGetAcMaintenanceListMutation();

  const [createMaintenance] = useCreateMaintenanceMutation();
  const [updateMaintenance] = useUpdateMaintenanceMutation();
  const [deleteMaintenance] = useDeleteMaintenanceMutation();

  // if (!isLoading) {
  //   console.log(companyWiseBranchList);
  // }

  useEffect(() => {
    getAcMaintenanceList()
      .unwrap()
      .then((payload) => setMaintenanceList(payload.data))
      .catch((error) => console.log(error?.status));
  }, [getAcMaintenanceList]);

  let defaultData = [];
  let data = [];

  if (true) {
    defaultData = [...maintenanceList];
    defaultData = defaultData?.map((roomMapInfo) => {
      const data = new Date(roomMapInfo.updated_at);
      const formattedDate = data.toLocaleString();
      return {
        ...roomMapInfo,
        updated_at: formattedDate,
      };
    });

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const handleEdit = (id) => {
    let formData = defaultData.find((object) => object.id == id) || {};
    setFormData(formData);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMaintenance({ id: id })
          .unwrap()
          .then((payload) => {
            Swal.fire("Deleted!", "Your item has been deleted.", "success");
            console.log(payload?.message);
            getAcMaintenanceList()
              .unwrap()
              .then((payload) => setMaintenanceList(payload?.data))
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    // alert(id);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
    setFormData({});
  };
  const dataChangeHandler = (e) => {
    setResponseMessage("");
    if (e.target) {
      setFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    } else {
      setFormData((previousValue) => ({
        ...previousValue,
        description: e,
      }));
    }
  };
  //for data table

  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (createFormSelector) {
      createMaintenance(formData)
        .unwrap()
        .then((payload) => {
          setResponseMessage(payload?.message);
          getAcMaintenanceList()
            .unwrap()
            .then((payload) => setMaintenanceList(payload?.data))
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          setResponseMessage(error?.status);
          console.log(error);
        });
    } else {
      updateMaintenance(formData)
        .unwrap()
        .then((payload) => {
          setResponseMessage(payload?.message);
          getAcMaintenanceList()
            .unwrap()
            .then((payload) => setMaintenanceList(payload?.data))
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          setResponseMessage(error?.status);
          console.log(error);
        });
    }
  };
  const handleClose = () => {
    setShow(false);
    setResponseMessage("");
    setFormData({});
  };

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    // console.log(searchFormData);
    getAcMaintenanceList(searchFormData)
      .unwrap()
      .then((payload) => {
        setMaintenanceList(payload?.data);
        setPage(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchChangeHandler = (e) => {
    if (e.target.value) {
      setSearchFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    } else if (e.target.value === "") {
      delete searchFormData[e.target.name];
    }
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Ac Maintenance
      </div>
      <div className="search-container">
        <div className="search-area" style={{ marginBottom: "1rem" }}>
          <form onSubmit={(e) => searchSumbitHandler(e)}>
            <div className="row">
              <div className="col-md-3 date-search-submit-btn">
                <select
                  className="form-select"
                  name="company_id"
                  onChange={(e) => {
                    searchChangeHandler(e);
                    setCompanyId(e.target.value);
                  }}
                  required
                >
                  <option value="" selected>
                    Select Company
                  </option>
                  {companyList?.data?.map((companyInfo) => {
                    return (
                      <option value={companyInfo?.id}>
                        {companyInfo?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 date-search-submit-btn">
                <select
                  className="form-select"
                  name="branch_id"
                  onChange={(e) => {
                    searchChangeHandler(e);
                    setBranchId(e.target.value);
                  }}
                >
                  <option value="" selected>
                    Select Branch
                  </option>
                  {companyWiseBranchList?.data?.map((branchInfo) => {
                    return (
                      <option value={branchInfo?.branch_id}>
                        {branchInfo?.branch_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 date-search-submit-btn">
                <select
                  className="form-select"
                  name="ac_id"
                  onChange={searchChangeHandler}
                >
                  <option value="" selected>
                    Select Ac
                  </option>
                  {companyAndBranchWiseAcList?.data?.map((acInfo) => {
                    return (
                      <option value={acInfo?.ac_id}>
                        {acInfo?.peripheral_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* <div className="col-md-3 form-group ">
                <label for="sensorUnit">Slave No.</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Insert Slave No."
                  name="slave_number"
                  onChange={searchChangeHandler}
                />
              </div> */}
              <div className="col-md-2 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="" style={{ marginTop: "10px" }}>
          <button
            type="submit"
            class="btn btn-primary col-md-12 "
            onClick={() => {
              setShow(true);
              setCreateFormSelector(true);
              setFormData({});
            }}
          >
            Create Maintenance
          </button>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Create Maintenance" : "Update Maintenance"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div className="form-group">
                      <label className="form-label">
                        Select Company <span style={{ color: "red" }}>*</span>
                      </label>

                      {createFormSelector == true ? (
                        <select
                          className="form-control form-select"
                          name="company_id"
                          value={formData?.company_id || ""}
                          onChange={(e) => {
                            dataChangeHandler(e);
                            setCompanyId(e.target.value);
                          }}
                          required
                        >
                          <option value="" label="Choose one" />
                          {companyList?.data?.map((companyInfo) => (
                            <option
                              key={companyInfo?.id}
                              value={companyInfo?.id}
                            >
                              {companyInfo?.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          name="company_name"
                          value={formData?.company_name || ""}
                          onChange={dataChangeHandler}
                          placeholder="Enter company name"
                          required
                          disabled
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Select Branch <span style={{ color: "red" }}>*</span>
                      </label>
                      {createFormSelector == true ? (
                        <select
                          class="form-control form-select"
                          name="branch_id"
                          value={formData?.branch_id || ""}
                          onChange={(e) => {
                            dataChangeHandler(e);
                            setBranchId(e.target.value);
                          }}
                          required
                        >
                          <option label="Choose one"></option>
                          {companyWiseBranchList?.data?.map((branchInfo) => {
                            return (
                              <option value={branchInfo?.branch_id}>
                                {branchInfo?.branch_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          name="branch_name"
                          value={formData?.branch_name || ""}
                          // onChange={dataChangeHandler}
                          // placeholder="Enter company name"
                          required
                          disabled
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Select Ac <span style={{ color: "red" }}>*</span>
                      </label>
                      {createFormSelector == true ? (
                        <select
                          class="form-control form-select"
                          name="ac_id"
                          value={formData?.ac_id || ""}
                          onChange={(e) => dataChangeHandler(e)}
                          required
                        >
                          <option label="Choose one"></option>
                          {companyAndBranchWiseAcList?.data?.map((acInfo) => {
                            return (
                              <option value={acInfo?.ac_id}>
                                {acInfo?.peripheral_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          name="type"
                          value={formData?.type || ""}
                          // onChange={dataChangeHandler}
                          // placeholder="Enter company name"
                          required
                          disabled
                        />
                      )}
                    </div>

                    <div className="form-group">
                      <label>Description</label>
                      <Input
                        name="description"
                        as="textarea"
                        rows={3}
                        placeholder="Description"
                        defaultValue={formData?.description || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Maintenance Date(year-month-date){" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="maintenance_date"
                        placeholder="Insert Date"
                        defaultValue={formData?.maintenance_date || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Remarks</label>
                      <input
                        type="text"
                        className="form-control"
                        name="remarks"
                        placeholder="Insert Remarks"
                        defaultValue={formData?.remarks || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table
            height={500}
            data={data || []}
            // loading={defaultData.length > 0 ? false : true}
            loading={!data && defaultData.length === 0}
          >
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
              fixed
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Company Name</HeaderCell>
              <Cell dataKey="company_name" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Branch Name</HeaderCell>
              <Cell dataKey="branch_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Type</HeaderCell>
              <Cell dataKey="type" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Description</HeaderCell>
              <Cell dataKey="description" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Maintenance Date
              </HeaderCell>
              <Cell dataKey="maintenance_date" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Remarks</HeaderCell>
              <Cell dataKey="remarks" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={50}
              align="center"
              // flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
            <Column
              width={100}
              align="center"
              // flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Delete</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="delete"
                    onClick={() => {
                      // setShow(true);
                      // setCreateFormSelector(false);

                      handleDelete(rowData.id);
                    }}
                  >
                    <AiFillDelete />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcMaintenance;
